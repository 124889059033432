import React, { useRef } from 'react';
import PropTypes from 'prop-types';
import { ClipLoader } from 'react-spinners';

const AvatarForm = (props) => {
  const { 
    isUpLoading, 
    label, 
    avatar,
    size,
  } = props;

  const fileInputRef = useRef();

  const handleFileUpload = () => {
    fileInputRef.current.click();
  };

  return (
    <div className="align-self-center">
      <input
        type="file"
        ref={fileInputRef}
        hidden
      />
      <button type="button" className="btn-avatar-upload" onClick={handleFileUpload}>
        {isUpLoading ? (
          <ClipLoader size={64} color="#ccc" />
        ) : (
          <img className="bot-avatar-image" width={size} height={size} src={avatar} alt="robot" />
        )}
      </button>
      <div className="d-flex justify-content-center"><span style={{ fontSize: '12px' }}>{label}</span></div>
    </div>
  );
};

AvatarForm.propTypes = {
  isUpLoading: PropTypes.bool.isRequired,
  avatar: PropTypes.string.isRequired,
  label: PropTypes.string.isRequired,
  size: PropTypes.number.isRequired,
};

export default AvatarForm;
