import React, { useState, useEffect, useRef } from 'react';
import PropTypes from 'prop-types';
import { ClipLoader } from 'react-spinners';
import { IoIosSave } from 'react-icons/io';
import AdminWrapper from '../admin/adminwrapper/wrapper';
import './index.css';
import ChatbotSettingsForms from './components/chatbotSettingsForms';

export const MARKETING_MODE = 'marketing';
export const WEB_APP_MODE = 'webapp';

const ChatbotSettings = (props) => {
  const { userId } = props;

  const [settingMode, setSettingsMode] = useState(MARKETING_MODE);
  const [isSaving, setIsSaving] = useState(false);

  const handleSubmit = () => {

  };

  return (
    <AdminWrapper>
      <div className="staff-container">
        <div className="container">
          <div className="row">
            <div className="col-md-12">
              <div className="main-titles-mob" style={{ display: 'flex', justifyContent: 'center' }}>
                <h3>Chatbot Settings</h3>
              </div>
            </div>
          </div>
          <div className="client-main-contact">
            <div className="d-flex justify-content-between">
              <div className="d-flex gap-1">
                <button type="button" className={settingMode === MARKETING_MODE ? 'chatbot-settings-mode-button-selected' : 'chatbot-settings-mode-button'} onClick={() => setSettingsMode(MARKETING_MODE)}>Marketing Website</button>
                <button type="button" className={settingMode === WEB_APP_MODE ? 'chatbot-settings-mode-button-selected' : 'chatbot-settings-mode-button'} onClick={() => setSettingsMode(WEB_APP_MODE)}>Web App</button>
              </div>
              
            </div>
            <div><span className="chatbot-settings-divider" /></div>
            <ChatbotSettingsForms settingMode={settingMode} />
            <div className="d-flex justify-content-end">
              <button className="btn btn-outline-secondary chatbot-setting-save-btn" type="button"> 
                {isSaving ? (
                  <ClipLoader size={18} />
                ) : (
                  <>
                    <IoIosSave
                      size={18}
                      style={{ marginRight: '16px' }}
                    />
                    <span>Save</span>
                  </>
                )}
              </button>
            </div>
          </div>
        </div>
      </div>
    </AdminWrapper>
  );
};

ChatbotSettings.propTypes = {
  userId: PropTypes.string.isRequired,
};

export default ChatbotSettings;
