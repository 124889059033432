import React, { useContext, useEffect, useState } from 'react';
import {
  Accordion, Button, Card, Dropdown, Form,
} from 'react-bootstrap';
import moment from 'moment';
import NumberFormat from 'react-number-format';
import { Navigate } from 'react-big-calendar';
import { useHistory } from 'react-router-dom/cjs/react-router-dom';
import AdminWrapper from '../admin/adminwrapper/wrapper';
import showmore from '../../assets/home/showmoreblue.png';
import { AuthDataContext } from '../../contexts/AuthDataContext';
import flag from '../../assets/admin/aus-flag.png';
import us from '../../assets/admin/us-flag.png';
import './subscription.css';
import EditBillingDetail from './EditBillingDetail';
import CancellationBilling from './CancellationBilling';
import Payments from './Payments';
import UpadateSubscription from './UpadateSubscription';
import CurrentSubscription from './CurrentSubscription';
import { countries, states } from '../country-state/country-state';
import { notifyAlert } from '../calculator/calculatorUtils';
import { userInstance } from '../../config/axios';
import { emailRegex } from '../../config/keys';
import { validateDataWithKey } from '../../functions';

function SubscribeBilling() {
  const { userData, setUserData } = useContext(AuthDataContext);
  const { userInformation } = userData || {};
  const [toggleData, setToggleData] = useState('Current Subscription');

  const [selectedCountry, setCountry] = useState('+61');
  const [isDropdownOpen, setIsDropdownOpen] = useState(false);
  const [mobileFormat, setMobileFormat] = useState('### ### ###');
  const [stateArray, setStateArray] = useState([]);
  const [error, setError] = useState('');

  console.log('toggleData====>', toggleData);

  const [subscriptionDetails, setSubcsriptionDetails] = useState({});
  // const [profileData, setProfileData] = useState(subscriptionDetails);
  // console.log('subscriptionDetails===>', subscriptionDetails);
  const editUserProfile = async (e) => {
    e.preventDefault();
    const {
      firstname, lastname, email, accessLevel, location, mobileNo, gender, state, postcode, streetAddress, suburb,
    } = subscriptionDetails;
    console.log('profileData', subscriptionDetails);
    try {
      const { isValid, emptyField } = validateDataWithKey({
        'first name': firstname.trim(),
        'last name': lastname.trim(),
        mobileNo,
        email,
        state,
        postcode,
        suburb,
        streetAddress,
      });

      if (isValid) {
        setError('');
        if (emailRegex.test(String(subscriptionDetails.email).toLowerCase())) {
          let isMobileValid = true;
          let isLandlineValid = true;
          // let mobileMsg = '';
          let errorMsg = '';
          // let landlineMsg = '';
          const payload = { ...subscriptionDetails, email: email.toLowerCase() };

          // console.log('selectedCountry', selectedCountry);
          console.log('payload', payload.mobileNo.length);
          if (selectedCountry === '+61') {
            if (payload.mobileNo.charAt(0) === '0') {
              payload.mobileNo = payload.mobileNo.replace('0', '');
            }

            if (payload.mobileNo.charAt(0) !== '4') {
              isMobileValid = false;
              errorMsg = 'Please start mobile number with 4';
              setError('mobileno');
            }
            if (payload.mobileNo.trim().length !== 11) {
              setError('mobileno');
              isMobileValid = false;
              // mobileMsg = 'Invalid Mobile Number';
              errorMsg = 'Enter 9 Digit Mobile Number';
            }
          }

          if (selectedCountry === '+1') {
            if (payload.mobileNo.charAt(0) === '0') {
              payload.mobileNo = payload.mobileNo.replace('0', '');
            }

            if (payload.mobileNo.trim().length !== 12) {
              setError('mobileno');
              isMobileValid = false;
              // mobileMsg = 'Invalid Mobile Number';
              errorMsg = 'Enter 10 Digit Mobile Number';
            }
          }

          if (isMobileValid) {
            payload.mobileNo = `${selectedCountry} ${payload.mobileNo}`;
            const response = await userInstance().put('/user/updateSubcriptionCardDetail', {
              ...payload,
            });

            // // console.log('sdsdsd', response);
            const { msg } = response.data;

            notifyAlert(msg, 'success');
            const copy = { ...userData };
            copy.userInformation = { ...copy.userInformation, ...payload };
            console.log({ copy });
            localStorage.setItem('apptData', JSON.stringify(copy));
            setUserData(copy);
          } else {
            notifyAlert(errorMsg, 'error');
          }
        } else {
          setError('email');
          notifyAlert('Invalid email', 'Error');
        }
      } else {
        setError(emptyField?.split(' ').join('').toLowerCase());
        notifyAlert(`Please fill ${emptyField}`, 'error');
      }
      setTimeout(() => {
        setError('');
      }, 1000);
    } catch (err) {
      // showErrMsg(err);
    }
  };

  const handleCountryStates = (compareValue) => {
    const fIndex = countries.findIndex((el) => el.name === compareValue);
    if (fIndex >= 0) {
      const copyStates = JSON.parse(JSON.stringify(states));
      const filteredArray = copyStates.filter((el) => el.countryCode === countries[fIndex].isoCode);
      const list = filteredArray.sort((a, b) => a.name.localeCompare(b.name));
      setCountry(`+${countries[fIndex].phonecode}`);
      setStateArray(filteredArray);
    }
  };

  const handleToggleSubscription = (data) => {
    if (data === 'Edit Billing Details') {
      handleCountryStates(subscriptionDetails?.country);
    }
    setToggleData(data);
    setIsDropdownOpen(false);
  };

  useEffect(() => {
    console.log('userInformation', userInformation);
    if (userInformation?._id) {
      setSubcsriptionDetails(JSON.parse(JSON.stringify(userInformation)));
    }
  }, [userInformation?._id]);

  console.log('subscriptionDetails', subscriptionDetails);
  const handleChange = (e) => {
    const { name, value } = e.target;

    const copy = { ...subscriptionDetails };

    if (name === 'country') {
      if (value === 'Australia') {
        setMobileFormat('### ### ###');
      }
      if (value === 'United States') {
        setMobileFormat('### ### ####');
      }
      handleCountryStates(value);
      copy.state = '';
    }

    const validateConditionalFields = () => {
      if (name === 'postcode' && value.length >= 10) {
        return false;
      }
      copy[name] = value;
      return true;
    };

    if (!validateConditionalFields()) return;
    setSubcsriptionDetails(copy);
  };
  const history = useHistory();
  const handleClose = () => {
    history.push('/calendar');
  };
  return (
    <AdminWrapper>
      <div className=" staff-container">
        <div className="container">
          <div className="row">
            <div className="col-md-12">
              <>
                <div className="back-option" role="presentation" onClick={handleClose}>
                  <span />
                  <i className="fa fa-times" aria-hidden="true" />
                </div>
                <div className="main-titles-mob">
                  <h3>Billing</h3>
                </div>

                <div className="target-heading align-items-center">
                  <h6 className="mb-0">
                    {toggleData}
                    {' '}
                  </h6>

                  <div className="client-option service-edit-icon">
                    <Dropdown show={isDropdownOpen} onToggle={(isOpen) => setIsDropdownOpen(isOpen)}>
                      <Dropdown.Toggle variant="success" id="dropdown-basic">
                        <img src={showmore} alt="show" />
                      </Dropdown.Toggle>
                      <Dropdown.Menu>
                        <div
                          className="action"
                          onClick={() => { handleToggleSubscription('Current Subscription'); }}
                          role="presentation"
                        >
                          <span>
                            Current Subscription
                          </span>
                        </div>
                        <div className="action" onClick={() => { handleToggleSubscription('Update Subscription'); }} role="presentation">

                          <span>
                            Update Subscription
                          </span>
                        </div>
                        <div
                          className="action"
                          onClick={() => { handleToggleSubscription('Payments'); }}
                          role="presentation"
                        >
                          <span>Payments</span>
                        </div>
                        <div
                          className="action"
                          onClick={() => { handleToggleSubscription('Cancel Subscription'); }}
                          role="presentation"
                        >
                          <span>Cancel Subscription</span>
                        </div>
                        <div
                          className="action"
                          onClick={() => { handleToggleSubscription('Edit Billing Details'); }}
                          role="presentation"
                        >
                          <span>Edit Billing Details</span>
                        </div>
                      </Dropdown.Menu>
                    </Dropdown>
                  </div>
                </div>
                {toggleData === 'Current Subscription'
                  && (
                    <CurrentSubscription subscriptionDetails={subscriptionDetails} />
                  )}
                {toggleData === 'Update Subscription'
                  && (
                    <>
                      <UpadateSubscription handleChange={handleChange} error={error} subscriptionDetails={subscriptionDetails} userInformation={userInformation} />
                    </>
                  )}

                {toggleData === 'Payments'
                  && (
                    <Payments />
                  )}

                {toggleData === 'Cancel Subscription'
                  && (
                    <>
                      <CancellationBilling userInformation={userInformation} />
                    </>
                  )}

                {toggleData === 'Edit Billing Details' && (
                  <EditBillingDetail
                    selectedCountry={selectedCountry}
                    flag={flag}
                    us={us}
                    subscriptionDetails={subscriptionDetails}
                    setMobileFormat={setMobileFormat}
                    mobileFormat={mobileFormat}
                    countries={countries}
                    stateArray={stateArray}
                    error={error}
                    handleChange={handleChange}
                    editUserProfile={editUserProfile}
                  />
                )}

              </>
            </div>
          </div>
        </div>
      </div>
    </AdminWrapper>
  );
}

export default SubscribeBilling;
