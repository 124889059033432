import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import AvatarForm from './avatarForm';
import TextField from './textField';
import TextArea from './textarea';
import KnowledgeForm from './knowledgeForm';
import SelectField from './selectField';

const modelList = [
  {
    id: 1532486145236475,
    label: 'GPT-4o Realtime Preview',
    value: 'gpt-4o-realtime-preview',
  },
  {
    id: 7263849102567384,
    label: 'GPT-4o Realtime Preview 2024-10-01',
    value: 'gpt-4o-realtime-preview-2024-10-01',
  },
  {
    id: 3847561928475639,
    label: 'GPT-4o 2024-08-06',
    value: 'gpt-4o-2024-08-06',
  },
  {
    id: 9273648102938475,
    label: 'GPT-4o Mini',
    value: 'gpt-4o-mini',
  },
  {
    id: 6357281940573826,
    label: 'GPT-4o Mini 2024-07-18',
    value: 'gpt-4o-mini-2024-07-18',
  },
  {
    id: 1395827461039485,
    label: 'GPT-4o 2024-05-13',
    value: 'gpt-4o-2024-05-13',
  },
  {
    id: 4857392104895731,
    label: 'GPT-4o',
    value: 'gpt-4o',
  },
  {
    id: 2948571620394758,
    label: 'GPT-4 Turbo 2024-04-09',
    value: 'gpt-4-turbo-2024-04-09',
  },
  {
    id: 9384756128394756,
    label: 'GPT-4 Turbo',
    value: 'gpt-4-turbo',
  },
  {
    id: 7483920156473829,
    label: 'GPT-3.5 Turbo 0125',
    value: 'gpt-3.5-turbo-0125',
  },
  {
    id: 5674839201583746,
    label: 'GPT-4 Turbo Preview',
    value: 'gpt-4-turbo-preview',
  },
  {
    id: 1039572846103948,
    label: 'GPT-4 0125 Preview',
    value: 'gpt-4-0125-preview',
  },
  {
    id: 8573920148573629,
    label: 'GPT-3.5 Turbo 1106',
    value: 'gpt-3.5-turbo-1106',
  },
  {
    id: 2048573910485723,
    label: 'GPT-4 1106 Preview',
    value: 'gpt-4-1106-preview',
  },
  {
    id: 1928374650193847,
    label: 'GPT-3.5 Turbo Instruct 0914',
    value: 'gpt-3.5-turbo-instruct-0914',
  },
  {
    id: 7483920156473821,
    label: 'GPT-3.5 Turbo Instruct',
    value: 'gpt-3.5-turbo-instruct',
  },
  {
    id: 5847392018573942,
    label: 'GPT-4 32K 0314',
    value: 'gpt-4-32k-0314',
  },
  {
    id: 2039485761039485,
    label: 'GPT-4',
    value: 'gpt-4',
  },
  {
    id: 9374856102938475,
    label: 'GPT-4 0314',
    value: 'gpt-4-0314',
  },
  {
    id: 8573920148573647,
    label: 'GPT-4 0613',
    value: 'gpt-4-0613',
  },
  {
    id: 1029384756102938,
    label: 'GPT-3.5 Turbo 16K',
    value: 'gpt-3.5-turbo-16k',
  },
  {
    id: 3847561928475631,
    label: 'GPT-3.5 Turbo',
    value: 'gpt-3.5-turbo',
  },
];
const SelectedModel = {
  marketing: 'gpt-4o-2024-08-06',
  webapp: 'gpt-4o',
};

const KnowledgeData = {
  marketing: [
    {
      id: 'P001a0h359s12001',
      name: 'The Complete Pool Management Handbook',
      pageNum: 200,
      type: 'PDF',
      url: 'https://chatbot/data-set/P001a0h359s12001.pdf',
    },
    {
      id: 'P002a0h359s12002',
      name: 'Efficient Pool Maintenance Techniques',
      pageNum: 150,
      type: 'TEXT',
      url: 'https://chatbot/data-set/P002a0h359s12002.txt',
    },
    {
      id: 'P003a0h359s12003',
      name: 'Pool Water Chemistry 101',
      pageNum: 180,
      type: 'PDF',
      url: 'https://chatbot/data-set/P003a0h359s12003.pdf',
    },
    {
      id: 'P004a0h359s12004',
      name: 'Guide to Pool Safety and Regulations',
      pageNum: 220,
      type: 'TEXT',
      url: 'https://chatbot/data-set/P004a0h359s12004.txt',
    },
    {
      id: 'P005a0h359s12005',
      name: 'Advanced Pool Cleaning Methods',
      pageNum: 170,
      type: 'PDF',
      url: 'https://chatbot/data-set/P005a0h359s12005.pdf',
    },
    {
      id: 'P006a0h359s12006',
      name: 'Understanding Pool Filtration Systems',
      pageNum: 145,
      type: 'TEXT',
      url: 'https://chatbot/data-set/P006a0h359s12006.txt',
    },
    {
      id: 'P007a0h359s12007',
      name: 'Seasonal Pool Care Strategies',
      pageNum: 160,
      type: 'PDF',
      url: 'https://chatbot/data-set/P007a0h359s12007.pdf',
    },
    {
      id: 'P008a0h359s12008',
      name: 'Pool Management for Beginners',
      pageNum: 130,
      type: 'TEXT',
      url: 'https://chatbot/data-set/P008a0h359s12008.txt',
    },
    {
      id: 'P009a0h359s12009',
      name: 'Innovative Pool Design Ideas',
      pageNum: 200,
      type: 'PDF',
      url: 'https://chatbot/data-set/P009a0h359s12009.pdf',
    },
    {
      id: 'P010a0h359s12010',
      name: 'Cost-Effective Pool Management',
      pageNum: 155,
      type: 'TEXT',
      url: 'https://chatbot/data-set/P010a0h359s12010.txt',
    },
    {
      id: 'P011a0h359s12011',
      name: 'Comprehensive Guide to Pool Chemicals',
      pageNum: 190,
      type: 'PDF',
      url: 'https://chatbot/data-set/P011a0h359s12011.pdf',
    },
    {
      id: 'P012a0h359s12012',
      name: 'Pool Equipment Troubleshooting',
      pageNum: 175,
      type: 'TEXT',
      url: 'https://chatbot/data-set/P012a0h359s12012.txt',
    },
    {
      id: 'P013a0h359s12013',
      name: 'Eco-Friendly Pool Management',
      pageNum: 140,
      type: 'PDF',
      url: 'https://chatbot/data-set/P013a0h359s12013.pdf',
    },
    {
      id: 'P014a0h359s12014',
      name: 'Pool Renovation and Upgrades',
      pageNum: 210,
      type: 'TEXT',
      url: 'https://chatbot/data-set/P014a0h359s12014.txt',
    },
    {
      id: 'P015a0h359s12015',
      name: 'Pool Design and Architecture',
      pageNum: 195,
      type: 'PDF',
      url: 'https://chatbot/data-set/P015a0h359s12015.pdf',
    },
    {
      id: 'P016a0h359s12016',
      name: 'Pool Pump and Heater Essentials',
      pageNum: 165,
      type: 'TEXT',
      url: 'https://chatbot/data-set/P016a0h359s12016.txt',
    },
    {
      id: 'P017a0h359s12017',
      name: 'Pool Safety and Accident Prevention',
      pageNum: 180,
      type: 'PDF',
      url: 'https://chatbot/data-set/P017a0h359s12017.pdf',
    },
    {
      id: 'P018a0h359s12018',
      name: 'Efficient Pool Energy Use',
      pageNum: 150,
      type: 'TEXT',
      url: 'https://chatbot/data-set/P018a0h359s12018.txt',
    },
    {
      id: 'P019a0h359s12019',
      name: 'Pool Landscaping and Aesthetics',
      pageNum: 210,
      type: 'PDF',
      url: 'https://chatbot/data-set/P019a0h359s12019.pdf',
    },
    {
      id: 'P020a0h359s12020',
      name: 'Pool Maintenance Checklist',
      pageNum: 125,
      type: 'TEXT',
      url: 'https://chatbot/data-set/P020a0h359s12020.txt',
    },
  ],
  webapp: [
    {
      id: 'P021a0h359s12021',
      name: 'Saltwater Pool Maintenance Guide',
      pageNum: 160,
      type: 'PDF',
      url: 'https://chatbot/data-set/P021a0h359s12021.pdf',
    },
    {
      id: 'P022a0h359s12022',
      name: 'DIY Pool Repair and Maintenance',
      pageNum: 175,
      type: 'TEXT',
      url: 'https://chatbot/data-set/P022a0h359s12022.txt',
    },
    {
      id: 'P023a0h359s12023',
      name: 'Swimming Pool Automation Systems',
      pageNum: 185,
      type: 'PDF',
      url: 'https://chatbot/data-set/P023a0h359s12023.pdf',
    },
    {
      id: 'P024a0h359s12024',
      name: 'Managing Commercial Pool Facilities',
      pageNum: 200,
      type: 'TEXT',
      url: 'https://chatbot/data-set/P024a0h359s12024.txt',
    },
    {
      id: 'P025a0h359s12025',
      name: 'Pool Construction and Design',
      pageNum: 220,
      type: 'PDF',
      url: 'https://chatbot/data-set/P025a0h359s12025.pdf',
    },
    {
      id: 'P026a0h359s12026',
      name: 'Hydraulics for Pool Operators',
      pageNum: 165,
      type: 'TEXT',
      url: 'https://chatbot/data-set/P026a0h359s12026.txt',
    },
    {
      id: 'P027a0h359s12027',
      name: 'Pool Service Business Essentials',
      pageNum: 190,
      type: 'PDF',
      url: 'https://chatbot/data-set/P027a0h359s12027.pdf',
    },
    {
      id: 'P028a0h359s12028',
      name: 'Pool Lighting and Effects',
      pageNum: 150,
      type: 'TEXT',
      url: 'https://chatbot/data-set/P028a0h359s12028.txt',
    },
    {
      id: 'P029a0h359s12029',
      name: 'Water Quality Management for Pools',
      pageNum: 175,
      type: 'PDF',
      url: 'https://chatbot/data-set/P029a0h359s12029.pdf',
    },
    {
      id: 'P030a0h359s12030',
      name: 'The Art of Pool Renovation',
      pageNum: 185,
      type: 'TEXT',
      url: 'https://chatbot/data-set/P030a0h359s12030.txt',
    },
  ],
};

const Greeting = {
  markting: 'Hello! Ask me any questions about our PoolWater-Pro software, or get help to solve your pool issues.',
  webapp: 'Hello! Ask me any questions about our PoolWater-Pro software, or get help to solve your pool issues.',
};

const SystemPrompt = {
  marketing: `You are a knowledgeable and approachable HelpBot for the PoolWater-Pro.com marketing website. Your role is to assist users with inquiries related to pool management and to provide insights into PoolWater-Pro, including its functionality, pricing, and features. Here are your specific tasks:
              1. Maintain Relevance: Politely inform users that your expertise is limited to pool management if they ask about unrelated topics.
              2. Pool Management Queries: Provide precise answers to pool management-related questions using the provided list. If the answer isn't available, use the OpenAI model to craft a response, clearly labeling it as AI-generated.
              3. Detail PoolWater-Pro: Clearly describe how PoolWater-Pro works, including its advantages and costs. Refer to the provided tutorials and data.
              4. Product Features: Explain the features of PoolWater-Pro comprehensively, ensuring clarity on what the product offers.
              5. User Engagement: Maintain a friendly and informative tone to keep the interaction engaging, gently highlighting the benefits of PoolWater-Pro.
              6. Localization: Use UK spelling and the metric system for interactions outside the US.
              7. User on the marketing website is not a subscriber and doesn’t have access to poolwater-pro web app. So, if a user has a question related to the PoolWater-Pro web app tutorial, you should politely inform them that they need to register first.
              8. If you provide part of the data as an answer, you must include a following reply after response unconditionally: Write “more” to get the balance of HelpBot’s reply.`,
  webapp: `You are a helpful and knowledgeable HelpBot designed for the PoolWater-Pro web app. Your primary responsibilities are to assist users with pool-related questions, guide new users through the initial setup, provide technical support, and escalate issues when necessary. Here are your specific tasks:8
            1. Maintain Topic Relevance: For inquiries unrelated to pool management or the use of PoolWater-Pro, politely inform users that your expertise is limited to pool management, and avoid providing information on unrelated topics.(required)
            2. Answer Pool Related Questions: Provide accurate and helpful responses to common questions about pool management, using the embedded list of questions and answers. If the information needed to answer a user's question is not in the embedded data, generate the response using the OpenAI model and clearly indicate that it is an AI-generated response.(required)
            3. Guided Setup: Walk new users through the initial setup of PoolWater-Pro, helping them personalize their settings. Address all questions related to using the software and the initial setup process.(required)
            4. Help Desk Support: Include links to video tutorials in your replies to help desk questions, directing users to additional resources for better understanding.(required)
            5. Technical Support: Offer detailed technical support responses. If a user finds the answer unsatisfactory, guide them to email the human support team at service@poolwaterpro.com. However, you cannot email the support team on behalf of the user.
            6. Localization Standards: Use UK spelling and metric measurements for non-US users.(required)`,
};

const BotName = {
  marketing: 'HelpBot',
  webapp: 'HelpBot',
};

const OpenAIKey = {
  marketing: 'sk-123456789123456789',
  webapp: 'sk-321654897321654987',
};

const ChatbotSettingsForms = (props) => {
  const { settingMode } = props;
  const [botName, setBotName] = useState(BotName[settingMode]);
  const [openAIKey, setOpenAIKey] = useState(OpenAIKey[settingMode]);
  const [aiModel, setAiModel] = useState(SelectedModel[settingMode]);
  const [greeting, setGreeting] = useState(Greeting[settingMode]);
  const [systemPrompt, setSystemPrompt] = useState(SystemPrompt[settingMode]);

  const handleChangeAIModel = (model) => {
    setAiModel(model);
  };

  const handleChangeBotName = (value) => {
    setBotName(value);
  };

  const handleChangeGreeting = (value) => {
    setGreeting(value);
  };

  const handleChangeSystemPrompt = (value) => {
    setSystemPrompt(value);
  };

  const handleChangeOpenAIKey = (value) => {
    setOpenAIKey(value);
  };

  useEffect(() => {
    setGreeting(Greeting[settingMode]);
    setSystemPrompt(SystemPrompt[settingMode]);
    setAiModel(SelectedModel[settingMode]);
    setBotName(BotName[settingMode]);
  }, [settingMode]);

  return (
    <div className="chatbot-settings-form-container">
      <div className="row gy-3">
        <div className="col-md-12 col-lg-5">
          <div className="row gap-3">
            <div className="col-md-12">
              <div className="chatbot-settings-form-sub-container">
                <div className="d-flex gap-3">
                  <AvatarForm
                    isUpLoading={false}
                    label="Avatar"
                    avatar="https://poolwater-pro.com/wp-content/uploads/2024/05/bot-e1716661303394.jpeg"
                    size={80}
                  />
                  <div className="d-flex align-self-center" style={{ width: '100%' }}>
                    <div className="align-self-center" style={{ width: '100%' }}>
                      <TextField 
                        type="text" 
                        label="Name" 
                        placeholder="" 
                        value={botName} 
                        handleChangeValue={handleChangeBotName} 
                      />
                      <TextField 
                        type="password" 
                        label="OpenAI API Key" 
                        placeholder="" 
                        value={openAIKey} 
                        handleChangeValue={handleChangeOpenAIKey} 
                      />
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="col-md-12">
              <div className="chatbot-settings-form-sub-container">
                <div className="row gy-3">
                  <div className="col-12">
                    <SelectField 
                      label="AI Model" 
                      data={modelList} 
                      selectedValue={aiModel} 
                      handleChangeSelect={handleChangeAIModel} 
                    />
                  </div>
                  <div className="col-12">
                    <TextArea type="text" label="Greeting Message" placeholder="" row={2} value={greeting} handleChangeValue={handleChangeGreeting} />
                  </div>
                  <div className="col-12">
                    <TextArea type="text" label="System Prompt" placeholder="" row={6} value={systemPrompt} handleChangeValue={handleChangeSystemPrompt} />
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="col-md-12 col-lg-7">
          <KnowledgeForm knowledgeData={KnowledgeData[settingMode]} />
        </div>
      </div>
    </div>
  );
};

ChatbotSettingsForms.propTypes = {
  settingMode: PropTypes.string.isRequired,
};

export default ChatbotSettingsForms;
