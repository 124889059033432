import React, { useRef, useState } from 'react';
import PropTypes from 'prop-types';
import { ClipLoader } from 'react-spinners';
import { IoCloudUploadOutline } from 'react-icons/io5';
import { BsFileEarmarkPdfFill } from 'react-icons/bs';
import { FaTrashCan } from 'react-icons/fa6';

const DataCard = (props) => {
  const { fileName, pageNum, handleDeleteData } = props;
  const isMouseOver = false;

  return (
    <div
      style={{ 
        display: 'flex',
        justifyContent: 'space-between',
        width: '100%', 
        border: '1px solid #ccc', 
        borderRadius: '8px', 
        padding: '4px 16px', 
      }}
    >
      <div className="d-flex gap-2 align-items-center">
        <BsFileEarmarkPdfFill size={18} />
        <span style={{ fontSize: '14px' }}>{fileName}</span>
      </div>
      <div className="d-flex gap-2 align-items-center">
        <div>
          <span style={{ fontSize: '14px' }}>
            Pages: &nbsp;
            {pageNum}
          </span>
        </div>
        <button 
          type="button" 
          onClick={handleDeleteData}
          style={{ background: 'transparent', border: 'none' }}
        >
          {
            !isMouseOver && <FaTrashCan size={18} />
          }
        </button>
      </div>
    </div>
  );
};

DataCard.propTypes = {
  fileName: PropTypes.string.isRequired,
  pageNum: PropTypes.number.isRequired,
  handleDeleteData: PropTypes.func.isRequired,
};

const KnowledgeForm = (props) => {
  const { knowledgeData } = props;

  const [isUpLoading, setIsUploading] = useState(false);
  const uploadDataRef = useRef();

  const handleUploadData = (e) => {
    console.log('knowledgeData');
    uploadDataRef.current.click();
  };

  const handleDeleteData = () => {
    console.log('asdfasf');
  };

  return (
    <div className="chatbot-settings-form-sub-container">
      <div className="d-flex flex-column">
        <div>
          <h6>Knowledge</h6>
        </div>
        <div className="mt-1">
          <input 
            type="file"
            ref={uploadDataRef}
            hidden
          />
          <button 
            className="btn btn-outline-secondary" 
            type="button" 
            onClick={handleUploadData}
            style={{
              padding: '4px 24px',
              fontSize: '14px',
            }}
          >
            <div className="align-self-center">
              {isUpLoading ? (
                <ClipLoader size={18} />
              ) : (
                <>
                  <IoCloudUploadOutline
                    size={18}
                    style={{ marginRight: '16px' }}
                  />
                  <span>Upload Data</span>
                </>
              )}
            </div>
            
          </button>
          
        </div>
        <div 
          className="mt-3" 
          style={{ 
            height: '412px', 
            overflowX: 'hidden', 
            overflowY: 'scroll', 
            paddingRight: '8px',
          }}
        >
          <div className="d-flex flex-column gap-2">
            {
              knowledgeData.map((item) => (
                <DataCard key={item.id} fileName={item.name} pageNum={item.pageNum} handleDeleteData={handleDeleteData} />
              ))
            }
          </div>
        </div>
      </div>
    </div>
  );
};

KnowledgeForm.propTypes = {
  knowledgeData: PropTypes.arrayOf.isRequired,
};

export default KnowledgeForm;
