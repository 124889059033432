import React, { useState, useEffect, useRef } from 'react';
import { Modal, Button, Spinner } from 'react-bootstrap';
import PropTypes from 'prop-types';
import moment from '../../functions/moment';
// import SquareCheckout from './squareCheckout';
import toastMessage from '../../functions/toastMessage';
import Loader from '../loader/loader';
import './payment.css';
import SubsPayment from '.';

const Testpouup = ({

  openPaymentForm,
  clientData,
  handleToken,
  handleAction,
  paymentResult,
  disableButton,
  smallLoader,
  returnPopUp,
  handleFormModal,
  paymentLoader,
}) => {
  const timepickerRef = useRef(null);
  const getDesiredEndDate = () => {
    const { duration } = clientData;
    let futureDate;
    if (duration === 12) {
      futureDate = moment(moment()).add(1, 'year').format('D MMM YYYY');
    } else if (duration === 24) {
      futureDate = moment(moment()).add(2, 'year').format('D MMM YYYY');
    } else if (duration === 36) {
      futureDate = moment(moment()).add(3, 'year').format('D MMM YYYY');
    } else {
      futureDate = moment(moment()).add(10, 'days').format('D MMM YYYY');
    }
    return futureDate;
  };
  
  return (
    <div className="staff-modal BillSummary">
      <Modal show={openPaymentForm} className="billSummary">
        {/* <Modal.Header closeButton>
          <Modal.Title>Modal heading</Modal.Title>
        </Modal.Header> */}
        <div className="back-popup crossBtn" onClick={() => handleAction(returnPopUp)}>
          <i className="las la-times" />
        </div>
        <Modal.Body>
          <div>
            <div className="billing-details-section">
              <h3 className="section-title">Billing Summary</h3>
              <div className="billl-content">
                <div className="upper-section">
                  <p className="bill-label">
                    Subscription Duration:
                    <span>
                      {clientData.duration}
                      {' '}
                      months
                    </span>
                    <span>
                      {' '}
                      {`(${moment(moment()).format('D MMM YYYY')}`}
                      {' '}
                      -
                      {' '}
                      {`${getDesiredEndDate()})`}
                      {/* {moment(userInformation?.endsubscriptionDate).format('D MMM YYYY')} */}
                      {' '}
                    </span>
                  </p>
                  {/* <p className="bill-value">(9 May 2023 - 8 May 2025)</p> */}
                </div>
                <p className="bill-label">
                  No. of Pools:
                  <span>
                    {clientData.numberOfPools}
                    {' '}
                    {clientData.numberOfPools === 1 ? 'Pool' : 'Pools'}
                  </span>
                </p>
                <p className="bill-label">
                  No. of Users:
                  <span>
                    {clientData.numberUsers}
                    {' '}
                    User

                  </span>
                </p>
              </div>
              {/* {clientData.extraCost && (
              <div className="total-cost">
                Cost to Pay:
                <span>
             
                  {clientData.country === 'Australia' ? 'AU' : 'US'}
                  $
                  {clientData?.extraCost?.toFixed(2)}
                </span>
              </div>
              )} */}
              <div className="total-cost">
                Total Cost:
                <span>
                  {clientData.country === 'Australia' ? 'AU' : 'US'}
                  $ 
                  {clientData?.totalCost?.toFixed(2)}
                </span>
              </div>
              {/* <input type='text' placeholder="Enter your username" /> */}
              <h3 className="section-title pt-12">Card Details</h3>
              <div className={smallLoader ? 'show-loader-payment' : 'hide-loader-payment'}>
                <div className="video-loader">
                  <Spinner animation="border" />
                </div>
            
                <div className="payment-form1">             
                  <SubsPayment
                    returnPopUp={returnPopUp}
                    handleFormModal={handleFormModal}
                    handleAction={handleAction}
                    clientData={clientData}
                    amountDisplay={clientData.extraCost ? clientData.extraCost : clientData.totalCost}
                  />
                  {/* <SquareCheckout handleToken={handleToken} amountDisplay={clientData.extraCost ? clientData.extraCost : clientData.totalCost} paymentLoader={paymentLoader} /> */}
                </div>
              </div>

            </div>
          </div>
        
        </Modal.Body>
        {/* <Modal.Footer>
      <Button variant="secondary">
        Close
      </Button>
      <Button variant="primary">
        Save Changes
      </Button>
    </Modal.Footer> */}
      </Modal>
    </div>
  );
};
Testpouup.propTypes = {
//   setDurationPicker: PropTypes.func.isRequired,
  clientData: PropTypes.objectOf.isRequired,
  handleToken: PropTypes.func.isRequired,
  returnPopUp: PropTypes.string.isRequired,
  handleAction: PropTypes.string.isRequired,
  smallLoader: PropTypes.bool.isRequired,
  paymentLoader: PropTypes.func.isRequired,
  handleFormModal: PropTypes.string.isRequired,
  openPaymentForm: PropTypes.bool.isRequired,
  paymentResult: PropTypes.bool.isRequired,
  disableButton: PropTypes.bool.isRequired,

};
export default Testpouup;
