import React from 'react';
import { Accordion, Button, Card } from 'react-bootstrap';
import PropTypes from 'prop-types';
import moment from 'moment';

const CancellationBilling = ({ userInformation }) => (
  <div className="target-heading  new-client-section flex-column">
    <h1 className="data_heading">Cancel Subscription</h1>
    <Accordion className="w-100">
      <Card.Body>
        <ul className="subscription_billing staff-list top-client-details ">
          <li className="staff-list-item">
            <div className="billing-box">
              <h3>Subscription Duration</h3>
              <h5>{userInformation?.duration}</h5>
            </div>
          </li>
          <li className="staff-list-item">
            <div className="billing-box">
              <h3>Start Date</h3>
              <h5> 
                {' '}
                {moment(userInformation?.startsubscriptionDate).format('DD MM YYYY')}
              </h5>
            </div>
          </li>
          <li className="staff-list-item">
            <div className="billing-box">
              <h3>End Date</h3>
              <h5> 
                {' '}
                {moment(userInformation?.endsubscriptionDate).format('DD MM YYYY')}
              </h5>
            </div>
          </li>
          <li className="staff-list-item">
            <div className="billing-box">
              <h3>No of Pools</h3>
              <h5>{userInformation?.numberOfPools}</h5>
            </div>
          </li>
          <li className="staff-list-item">
            <div className="billing-box">
              <h3>No of Users</h3>
              <h5>{userInformation?.numberUsers}</h5>
            </div>
          </li>
          <li className="staff-list-item">
            <div className="billing-box">
              <h3>Total Cost</h3>
              <h5>{`${userInformation.country === 'Australia' ? 'AUD$' : '$'}${userInformation.totalCost}`}</h5>
            </div>
          </li>

        </ul>
        <div className="data_heading cancel_data mt-4">
          <p>
            Your PoolWater-Pro subscription is set to auto-renew
            on 20 Jul 2024 and every 12 months thereafter, based
            on your current subscription selection.
          </p>
          <p>
            Please note that one month’s notice is required for
            cancellation before the end of your current
            subscription term. No refunds will be issued for early
            cancellation. You will retain access to the service until
            the conclusion of your current subscription period.
          </p>
          <p>
            Please contact 
            {' '}
            <a href="https://app.poolwater-pro.com/">PoolWater-Pro support</a>
            {' '}
            if you need
            further assistance.
          </p>
          <div className="row">
            <div className="col-md-12">
              <div className="view-btn staff-form-btn d-flex justify-content-between">
                <Button type="button" className="btn btn-edit"> Cancel</Button>
                <Button type="Submit" className="btn btn-save btn-back">Back</Button>
                
              </div>
            </div>
          </div>
        </div>
       
      </Card.Body>
    </Accordion>
  </div>
);

export default CancellationBilling;

CancellationBilling.propTypes = {
  userInformation: PropTypes.string.isRequired,

};
