/* eslint-disable react/prop-types */
import React, { useEffect, useContext, useCallback } from 'react';
import {
  BrowserRouter as Router,
  Route,
  Switch,
  Redirect,
} from 'react-router-dom';

import 'bootstrap/dist/css/bootstrap.min.css';
import 'react-toastify/dist/ReactToastify.css';
import { NotificationContainer } from 'react-notifications';
import { ToastContainer } from 'react-toastify';
import moment from './functions/moment';
import Home from './components/home/index';
import Login from './components/login/login';
import Contact from './components/contact/contact';
import Calculator from './components/calculator/calculator';
import PoolSetUp from './components/poolsetup/poolsetup';
import AdminDashboard from './components/admin/admindashboard/dashboard';
import UserRoute from './components/PrivateRoutes/User';
import LoginRoute from './components/PrivateRoutes/Login';
import history from './config/history';
import { AuthDataContext } from './contexts/AuthDataContext';
import HeaderDataProvider from './contexts/HeaderContext';
import UpdatePassword from './components/updatepassword/updatepassword';
import NotAuthorized from './components/defaultpages/401';
import StaffCalendar from './components/calendar/calendar';
import { userInstance } from './config/axios';
import Invoice from './components/invoices/invoice';
import FinalizeVisitcost from './components/appointment/finalizeVisitCost';
import Awaitingapprovals from './components/appointment/awaitingSendapprovals';
import PastFutureBooking from './components/payments/pastFutureBooking';
import WaterTestHistory from './components/payments/waterTestHistory';
import PaymentReceived from './components/payments/paymentsRecieved';
import Quotes from './components/invoices/quotes';
import InvoicesPayment from './components/payments/invoicesPayments';
import ResetPassword from './components/login/resetPassword';
import CompleteAppointmentView from './components/appointment/completeappointmentview';
import AddAppointment from './components/appointment/addappointment';
import CalendarSettings from './components/calendarSetting/calendarSetting';
import Reports from './components/reports/reports';
// import Calendarsettings from './components/calendarSetting/calendarSetting';
import 'react-notifications/lib/notifications.css';
import Inventory from './components/inventory/inventory';
import SmsTemplate from './components/smsTemplate/smsTemplate';
import './components/payments/payment.css';
import './components/watertesthistory/professionaluser.css';
import Subscription from './components/subscription/subscription';
import NewPermissionLayout from './components/permissionlevels/newPermissionLevel';
import StaffTable from './components/staff/staffTable';
import Staff from './components/staff';
import SubscriptionOrdering from './components/subscriptionOrdering/subscriptionOrdering';
import './components/prduct&services/product&services.css';
import './components/prduct&services/webfile.css';
import './components/staff/staff.css';
import './components/clients/client.css';
import './components/equipmentDetails/equipment.css';
import { checkAuth, queryString } from './functions';
import UserPool from './components/poolsetup/userPool';
import MyProfile from './components/myProfile/profile';
import './App.css';
import './style.css';
import './responsive.css';
import './mobile.css';
import { getTimeZone } from './functions/timeDate';
import VideoTutorialPopUp from './components/videoTutorials/videoTutorial';
import VideoViewPopUp from './components/videoTutorials/videoViewPopUp';
import ChatbotSettings from './components/chatbotSettings';
import SubscribeBilling from './components/subscriptionManagement/SubscriberBilling';
// import SubscribeBilling from './components/subscriptionManagement/SubscriberBilling.jsx';

function App() {
  const {
    userData,
    setUserData,
    clientAllData,
    setClientAllData,
    setLocationInfo,
  } = useContext(AuthDataContext); 
  let {
    userId,
    token: userToken,
    authToken: staffToken,
    appointmentId,
    chemicaltype,
    appId,
  } = queryString();

  let staff_id = localStorage.getItem('staff_id');
  let view = localStorage.getItem('view');
  const handleAppLogin = () => {
    localStorage.setItem('s#@4f', userToken);
    localStorage.setItem('f#@5e', staffToken);
    localStorage.setItem('loginStaff', userId);
    localStorage.setItem('staff_id', userId);

    localStorage.setItem('s#@4f11', userToken);
    localStorage.setItem('f#@5e11', staffToken);
    localStorage.setItem('loginStaff11', userId);
    localStorage.setItem('staff_id11', userId);
    validateToken();
    setFirstActiveStaff();
    settinngUserDetais();
    let calendarUrl = '';
    if (!chemicaltype) {
      calendarUrl = `/calendar?activedate=${moment(new Date()).format(
        'YYYY-MM-DD',
      )}&view=${view || 'timeGridWeek'}&staff_id=${
        userId || ''
      }&today=today&userId=${userId}&token=${userToken}&authToken=${staffToken}`;
    } else {
      calendarUrl = `/calendar?activedate=${moment(new Date()).format(
        'YYYY-MM-DD',
      )}&view=${view || 'timeGridWeek'}&staff_id=${
        userId || ''
      }&appointmentId=${appointmentId}&chemicaltype=${chemicaltype}&appId=${appId}&userId=${userId}&token=${userToken}&authToken=${staffToken}`;
    }
    history.push(calendarUrl);
  };

  useEffect(() => {
    if (userId && userToken && staffToken) {
      handleAppLogin();
    }
  }, [userId, userToken, staffToken]);

  const validateToken = useCallback(async () => {
    try {
      localStorage.removeItem('actionName');
      localStorage.removeItem('firstLogin');
      if (localStorage.getItem('f#@5e') || userToken || staffToken) {
        const response = await userInstance().get('/user/validatetoken');
        const permissionlist = await userInstance().get(
          '/userPermissions/getAllPermissionList',
        );

        const { userdata, calendarSettings, userDetails } = response.data;

        const { permissionList } = permissionlist.data;
        const {
          email,
          accessLevel,
          firstLogin,
          userid,
          numberUsers,
          numberOfPools,
          isOwner,
          role,
          invoicePhone,
          istwilio,
          invoiceEmail,
          isEmail,
          isNumberVerify,
          isEmailVerify,
          chemicalsToDisplay,
        } = userdata;

        setUserData({
          ...userData,
          auth: true,
          email,
          accessLevel,
          firstLogin,
          permissions: permissionList || [],
          calendarSettings,
          numberUsers,
          numberOfPools,
          userPermissions: permissionList || [],
          role,
          userDetails,
          industryId: userDetails?.industryId,
          invoicePhone,
          istwilio: istwilio || false,
          invoiceEmail,
          isEmail: isEmail || false,
          isNumberVerify: isNumberVerify || false,
          isEmailVerify: isEmailVerify || false,
          userId: userid,
          chemicalsToDisplay,
          userInformation: userdata,
          isOwner,
        });

        // const addTimeZone = new TimeZoneManage(calendarSettings);

        console.log('ddd---', getTimeZone(calendarSettings?.timeZone, '2024-05-14T14:45:00+05:30')); 

        // const date = getTimeZone(calendarSettings?.timeZone, new Date());
        // console.log('---->dddd', new Date(date).toISOString());

        if (
          userData.firstLogin === true
          && window.location.pathname !== '/update-password'
        ) {
          window.location.href = '/update-password';
        }
      } else {
        setUserData({ auth: false });
      }
    } catch (error) {
      console.log('error', error?.response?.data);

      if (localStorage.getItem('f#@5e')) {
        if (error?.response?.data) {
          const { code, msg } = error.response.data || {};
          if (code === 401) {
            localStorage.removeItem('s#@4f');
            localStorage.removeItem('f#@5e');
            window.location.href = '/login';
          }
        }
      }
    }
  }, []);
  const setFirstActiveStaff = async () => {
    const checkExistStaff = localStorage.getItem('staff_id');
    if (
      localStorage.getItem('f#@5e')
      && (checkExistStaff === null
        || checkExistStaff === ''
        || checkExistStaff === 'undefined')
    ) {
      const response = await userInstance().get(
        `/staff/checkExistStaff?id=${checkExistStaff}`,
      );
      const { staffData } = response.data || {};
      const {
        firstname, lastname, location, _id, 
      } = staffData || {};
      localStorage.setItem('staff_id', _id);
      localStorage.setItem('staff_name', `${firstname} ${lastname}`);
      localStorage.setItem('locationId', location);
    }
  };

  const settinngUserDetais = () => {
    const data = localStorage.getItem('apptData');
    if (data) {
      const parseData = JSON.parse(data);
      setClientAllData(parseData);
    }
  };
  useEffect(() => {
    if (!userId && !userToken && !staffToken) {
      validateToken();
      setFirstActiveStaff();
      settinngUserDetais();
    }
  }, [validateToken]);

  return (
    <div className="App">
      <Router history={history}>
        <Switch>
          <LoginRoute exact path="/" component={() => <Login />} />
          <LoginRoute exact path="/login" component={() => <Login />} />

          <Route
            exact
            path="/update-password"
            render={() => <UpdatePassword />}
          />
          <Route exact path="/401" render={() => <NotAuthorized />} />
          <LoginRoute
            exact
            path="/forget-password"
            component={() => <ResetPassword />}
          />
          {/* <LoginRoute exact path="/update-password" component={() => <UpdatePassword />} /> */}
          <Route exact path="/user-pool" component={() => <UserPool />} />
          {/* <Route exact path="/video-tutorials" component={() => <VideoTutorialPopUp />} /> */}
          <Route exact path="/video-tutorials/:id" component={() => <VideoViewPopUp />} />

          {userData?.accessLevel ? (
            <> 
              <LoginRoute exact path="/" component={() => <Login />} />
              <LoginRoute exact path="/login" component={() => <Login />} />
              <UserRoute
                exact
                path="/invoice-details"
                component={() => <SmsTemplate />}
              />
              <UserRoute
                exact
                path="/video-tutorials/:id"
                component={() => <VideoViewPopUp />}
              />

              <Route
                exact
                path="/calendar"
                render={() => (
                  <HeaderDataProvider>
                    {userToken && staffToken && userId ? (
                      <>
                        <StaffCalendar />
                      </>
                    ) : (
                      <>
                        {localStorage.getItem('s#@4f')
                        && checkAuth(
                          userData.accessLevel,
                          window.location.pathname,
                          userData.permissions,
                        ) ? (
                            userData.firstLogin === true ? (
                              <Redirect to="/update-password" />
                            ) : (
                              <StaffCalendar />
                            )
                          ) : !localStorage.getItem('s#@4f') ? (
                            <Redirect to="/login" />
                          ) : (
                            <NotAuthorized />
                          )}
                      </>
                    )}
                  </HeaderDataProvider>
                )}
              />

              <UserRoute exact path="/quotes" component={() => <Quotes />} />

              <UserRoute
                exact
                path="/subscriptions"
                component={() => <Subscription />}
              />

              <UserRoute
                exact
                path="/calculator"
                component={() => <Calculator />}
              />
              <UserRoute
                exact
                path="/dashboard"
                component={() => <AdminDashboard />}
              />

              <UserRoute
                exact
                path="/poolsetup"
                component={() => <PoolSetUp />}
              />

              <UserRoute
                exact
                path="/addappointment"
                component={() => <AddAppointment />}
              />

              {/* <Route exact path="/invoice" component={() => <Invoice />} /> */}
              <Route exact path="/payment" component={() => <Invoice />} />
              <UserRoute
                exact
                path="/finlizevisitcost"
                component={() => <FinalizeVisitcost />}
              />
              <UserRoute
                exact
                path="/payemnt-received"
                component={() => <PaymentReceived />}
              />
              <UserRoute
                exact
                path="/approvalappointments"
                component={() => <Awaitingapprovals />}
              />
              <UserRoute
                exact
                path="/booking"
                component={() => <PastFutureBooking />}
              />
              <UserRoute
                exact
                path="/watertesthistory"
                component={() => <WaterTestHistory />}
              />
              <UserRoute
                exact
                path="/invoicespayment"
                component={() => <InvoicesPayment />}
              />
              <UserRoute
                exact
                path="/completeview"
                component={() => <CompleteAppointmentView />}
              />

              {/* <UserRoute
                exact
                path="/edit-templete"
                component={() => <EditMainTemplate />}
              /> */}
              <UserRoute
                exact
                path="/calendarSettings"
                component={() => <CalendarSettings />}
              />

              <UserRoute
                exact
                path="/inventory"
                component={() => <Inventory />}
              />

              <UserRoute
                exact
                path="/userPermissions"
                component={() => (
                  <NewPermissionLayout userId={userData?.userId} />
                )}
              />
              <UserRoute
                exact
                path="/chatbotSettings"
                component={() => (
                  <ChatbotSettings userId={userData?.userId} />
                )}
              />
              <UserRoute exact path="/users" component={() => <Staff />} />
              <UserRoute exact path="/subscription-management" component={() => <SubscribeBilling />} />
              <UserRoute
                exact
                path="/predictiveOrdering"
                component={() => <SubscriptionOrdering />}
              />
              <UserRoute exact path="/contact" component={() => <Contact />} />
              <UserRoute
                exact
                path="/myprofile"
                component={() => <MyProfile />}
              />
              <Route exact path="/reports" render={() => <Reports />} />
              {/* <Route exact path="/chatBot" render={() => <ChatBot />} /> */}
            </>
          ) : !localStorage.getItem('s#@4f') ? (
            <Redirect to="/login" />
          ) : null}
        </Switch>
      </Router>
      <ToastContainer autoClose={500} />
      <NotificationContainer timeOut={500} />
    </div>
  );
}

export default App;
