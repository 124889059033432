import moment from 'moment';
import React from 'react';
import {
  Accordion, Button, Card, Form, 
} from 'react-bootstrap';
import PropTypes from 'prop-types';
import { billing_pools, billing_rules, billing_users } from '../subscription/utils';

const UpadateSubscription = ({
  subscriptionDetails, error, handleChange, userInformation, 
}) => (
  <div className="target-heading new-client-section flex-column">
    <h1 className="data_heading">Current Subscription</h1>
    <Accordion className="w-100">
      <Card.Body>
        <ul className="subscription_billing staff-list top-client-details1 ">
          <li className="staff-list-item">
            <div className="billing-box">
              <h3>Subscription Duration</h3>
              <h5>{userInformation?.duration}</h5>
            </div>
          </li>
          <li className="staff-list-item">
            <div className="billing-box">
              <h3>Start Date</h3>
              <h5> 
                {' '}
                {moment(userInformation?.startsubscriptionDate).format('DD MM YYYY')}
              </h5>
            </div>
          </li>
          <li className="staff-list-item">
            <div className="billing-box">
              <h3>End Date</h3>
              <h5> 
                {' '}
                {moment(userInformation?.endsubscriptionDate).format('DD MM YYYY')}
              </h5>
            </div>
          </li>
          <li className="staff-list-item">
            <div className="billing-box">
              <h3>No of Pools</h3>
              <h5>{userInformation?.numberOfPools}</h5>
            </div>
          </li>
          <li className="staff-list-item">
            <div className="billing-box">
              <h3>No of Users</h3>
              <h5>{userInformation?.numberUsers}</h5>
            </div>
          </li>
          <li className="staff-list-item">
            <div className="billing-box">
              <h3>Total Cost</h3>
              <h5>{`${userInformation.country === 'Australia' ? 'AUD$' : '$'}${userInformation.totalCost}`}</h5>
            </div>
          </li>
          <div className="staff-form w-100">
            <div className="row">
              <div className="col-md-12 detailed-data">
                <Form.Group controlId="formBasicLocation">
                  <Form.Label>
                    Subscription Duration
                  </Form.Label>
                  <div className="select-box">
                    <Form.Control
                      as="select"
                      name="duration"
                      onChange={(e) => handleChange(e)}
                      value={subscriptionDetails.duration}
                      className={`${
                        error === 'subscriptionduration'
                          ? 'error-text'
                          : ''
                      }`}
                    >
                      <option value="">Select</option>
                      {billing_rules[subscriptionDetails.country].map((el) => <option value={el.duration}>{el.description}</option>)}
             
                    </Form.Control>
                  </div>
                </Form.Group>
              </div>
            </div>
            <div className="row">
              <div className="col-md-12 detailed-data">
                <Form.Group controlId="formBasicLocation">
                  <Form.Label>
                    No. of Pools
                  </Form.Label>
                  <div className="select-box">
                    <Form.Control
                      as="select"
              // value={clientData.numberOfPools}
                      // name="numberOfPools"
                      value={subscriptionDetails.numberOfPools}
                      name="numberOfPools"
                      onChange={(e) => handleChange(e)}
                      className={`${
                        error === 'numberofpools'
                          ? 'error-text'
                          : ''
                      }`}
                    >
                      {/* <option value="">Select</option>/ */}
                      <option value="">Select</option>
                      {billing_pools[subscriptionDetails.country].map((el) => (
                        <option value={el.num}>
                          {el.description}
                        </option>
                      ))}
             
                    </Form.Control>
                  </div>
                </Form.Group>
              </div>
            </div>
            <div className="row">
              <div className="col-md-12 detailed-data">
                <Form.Group controlId="formBasicLocation">
                  <Form.Label>
                    No. of Users
                  </Form.Label>
                  <div className="select-box">
                    <Form.Control
                      as="select"
                      value={subscriptionDetails.numberUsers}
                      name="numberUsers"
                      onChange={(e) => handleChange(e)}
                      className={`${
                        error === 'numberofuser'
                          ? 'error-text'
                          : ''
                      }`}
                    >
                      <option value="">Select</option>
                      {billing_users[subscriptionDetails.country].map((el) => (
                        <option value={el.num}>
                          {el.description}
                        </option>
                      ))}
             
                    </Form.Control>
                  </div>
                </Form.Group>
              </div>
            </div>
            <div className="row">
              <div className="col-md-12 detailed-data">
                <Form.Group controlId="formBasicLocation">
                  <Form.Label>
                    Update Subscription Effective From
                  </Form.Label>
                  <div className="select-box">
                    <Form.Control
                      as="select"
              // value={clientData.numberOfPools}
                      name="numberOfPools"
                    >
                      <option value="">Select</option>
                      <option value="">
                        Update Immediately
                      </option>
                      <option value="">
                        Update From Specific Date
                      </option>
                      <option>
                        Update at End of Current Subscription
                      </option>
             
                    </Form.Control>
                  </div>
                </Form.Group>
              </div>
            </div>
            <div className="row">
              <div className="col-md-12 detailed-data">
                <Form.Group controlId="formBasicLocation" className="m-0">
                  <Form.Label>
                    Total Cost:AUD$594
                  </Form.Label>
       
                </Form.Group>
              </div>
            </div>
            <div className="row">
              <div className="col-md-12">
                <div className="view-btn staff-form-btn">
                  <Button type="button" className="btn btn-edit"> Cancel </Button>
                  <Button type="Submit" className="btn btn-save"> Save/Update </Button>
                </div>
              </div>
            </div>
          </div>
        </ul>
      </Card.Body>
    </Accordion>
  </div>
);

export default UpadateSubscription;

UpadateSubscription.propTypes = {
  userInformation: PropTypes.func.isRequired,
  handleChange: PropTypes.func.isRequired,
  error: PropTypes.string.isRequired,
  subscriptionDetails: PropTypes.objectOf({}).isRequired,
};
