import React from 'react';

const Payments = () => (
  <div className="row">
    <div className="col-md-12">
      <div className="pool-setup">
        <div className="pool-chemistry-box chemistry-target m-0">
          <div className="pool-analysis">
           
            <div className="right-pool-analysis payments-billing w-100">
              <h6>Date</h6>
              <h6>Amount</h6>
              <h6>Subscription Duration</h6>
              <h6>Transaction ID</h6>
              <h6>Status</h6>
            </div>
          </div>
          <div className="pool-analysis">
           
            <div className="right-pool-analysis payments-billing w-100">
              <p>20 jul 2024</p>
              <p>Amount</p>
              <p>Subscription Duration</p>
              <p>Transaction ID cs sddsf saddsf sdfdf sqdwqed sdew</p>
              <p>Completed</p>
            </div>
            
          </div>
          <div className="pool-analysis">
           
            <div className="right-pool-analysis payments-billing w-100">
              <p>20 jul 2024</p>
              <p>Amount</p>
              <p>Subscription Duration</p>
              <p>Transaction ID cs sddsf saddsf sdfdf sqdwqed sdew</p>
              <p>Completed</p>
            </div>
           
          </div>
        </div>
      </div>
    </div>
  </div>
);

export default Payments;
